import $ from 'jquery';
import { Base64 } from 'js-base64';
import Storage from './storage';
import errorDefinition from './errors';
import { baseApiURL } from "../Utilities/utility";
import axios from "axios";


export default class SCORMAdapter {
     
    loadCourse(courseUrl, courseId, userId, suspendData) {
        window.localStorage.setItem('courseId', courseId);
        window.localStorage.setItem('userId', userId);
        window.localStorage.setItem('suspendData', suspendData);
        window.open(courseUrl, "newwindow", "height=800, width=1000, toolbar=no, menubar=no, scrollbars=no,location=no,resizable=yes, status=no");
    }


    LMSInitialize() {
        Storage.setItem('Initialized', true);
        return true;
    }

    LMSFinish() {
        if (!this._isInitialized()) {
            this._setError(301);
            return false;
        }
        Storage.setItem('Initialized', false);
        let _return = this.LMSCommit();
        let item = window.localStorage.getItem('scormData');
        var itemParse = JSON.parse(item);
        const courseData = {
            suspend_data: "",
            score: "",
            courseState:""
        }
        if (itemParse.hasOwnProperty('cmi.suspend_data'))
            courseData.suspend_data = itemParse["cmi.suspend_data"];
        if (itemParse.hasOwnProperty('cmi.core.score.raw'))
            courseData.score = itemParse["cmi.core.score.raw"];
        if (itemParse.hasOwnProperty('cmi.core.lesson_status'))
            courseData.courseState = itemParse["cmi.core.lesson_status"];

        let userID = window.localStorage.getItem('userId');
        let courseID = window.localStorage.getItem('courseId');

        var objData = {
            "SuspendData": courseData.suspend_data,
            "Score": courseData.score,
            "UserId": userID,
            "CourseId": courseID,
            "CourseState": courseData.courseState,
        }

        const options = {
            async: false,
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json"
            },
            data: JSON.stringify(objData),
        };
        axios
            .post(
                //baseApiURL + "/Course/SaveUserCourseDetails?suspendData=" + objData + "&score=" + courseData.score + "&userId=" + userID
                //+ "&courseId=" + courseID + "&courseState=" + courseData.courseState,
                //options

                 baseApiURL + "/Course/SaveUserCourseDetails" ,
                objData
            )
            .then((response) => {
                Storage.clearAll();
                window.location.reload();
                return _return;
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
    }

    LMSGetValue(element) {
        if (!this._isInitialized()) {
            this._setError(301);
            return false;
        }
        let value = Storage.getItem(element);
        if (!value) {
            this._setError(201);
            return "";
        }
        return value;
    }

    LMSSetValue(element, value) {
        if (!this._isInitialized()) {
            this._setError(301);
            return false;
        }
        Storage.setItem(element, value);
        return Storage.getItem(element);
    }

    LMSCommit() {
        let data = Storage.getAll();
        delete data['errors'];
        delete data['Initialized'];
        data = JSON.stringify(data);
        data = Base64.encode(data);
        let response;
        let _return = true;
        //$.post({
        //    url: baseApiURL + "/Course/SaveUserCourseDetails?newData=" + item,
        //    async: false,
        //    data: { data },
        //    success: res => {
        //        response = res;
        //    },
        //    error: () => {
        //        this._setError(101);
        //        _return = false;
        //    }
        //});
        return _return;
    }

    LMSGetLastError() {
        let errors = Storage.getItem('errors');
        errors = JSON.parse(errors);
        if (errors && errors.length > 0) {
            return errors.pop();
        }
        return "";
    }

    LMSGetErrorString(errorCode) {
        errorCode = errorCode.toString();
        let error = errorDefinition[errorCode];
        if (!error) return "";
        return error["errorString"];
    }

    LMSGetDiagnostic(errorCode) {
        errorCode = errorCode.toString();
        let error = errorDefinition[errorCode];
        if (!error) return "";
        return error["diagnostic"];
    }

    _isInitialized() {
        let initialized = Storage.getItem('Initialized');
        return initialized;
    }

    _setError(errorCode) {
        errorCode = errorCode.toString();
        let errors = Storage.getItem('errors');
        if (!errors) errors = '[]';
        errors = JSON.parse(errors);
        errors.push(errorCode);
        errors = JSON.stringify(errors);
        Storage.setItem('errors', errors);
    }
}
