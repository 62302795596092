import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    rootBox: {
        color: `#FFF`,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    footerNav: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginRight: 'auto',
        color: `#FFF`,
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(0),

        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginLeft: 'auto',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2),
        }
    },
    footerLink: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        color: `#FFF`,
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(2),
        }
    },
}));

export default function Footer(props) {
    const classes = useStyles();

    const content = {
        'brand': '© Siemens 1996 - 2022',
        'link1': 'Corporate Information',
        'link2': 'Privacy Policy',
        'link3': 'Cookie Policy',
        'link4': 'Terms of use',
        'link5': 'Digital ID ',
        ...props.content
    };

    
    return (
        <footer>
            <Container maxWidth="xl">
                <Box pt={10} display="flex" flexWrap="wrap" alignItems="center" className={classes.rootBox}>
                    
                        {content.brand}
                    
                    <Box component="nav" className={classes.footerNav}>
                        <Link href="https://new.siemens.com/in/en/general/legal.html" variant="body1" className={classes.footerLink}>{content['link1']}</Link>
                        <Link href="https://new.siemens.com/in/en/general/legal/privacy-notice.html" variant="body1" className={classes.footerLink}>{content['link2']}</Link>
                        <Link href="https://new.siemens.com/in/en/general/legal/cookie-notice.html" variant="body1" className={classes.footerLink}>{content['link3']}</Link>
                        <Link href="https://new.siemens.com/in/en/general/legal/terms-of-use.html" variant="body1" className={classes.footerLink}>{content['link4']}</Link>
                        <Link href="https://new.siemens.com/in/en/general/legal/digital-id.html" variant="body1" className={classes.footerLink}>{content['link5']}</Link>
                    </Box>
                    
                </Box>
            </Container>
        </footer>
    );
}