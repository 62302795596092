import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import { tableHeaderStyle } from "../../SharedComponents/SharedComponentStyles";
//import Alert from "@material-ui/lab/Alert";
import { baseApiURL } from "../../Utilities/utility";
import { useDispatch, useSelector } from "react-redux";
import SnackbarCustom from "../../SharedComponents/common/Snackbar/Snackbar";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => (
        <Clear {...props} ref={ref} className="bg-transparent" />
    )),
    Search: forwardRef((props, ref) => (
        <Search {...props} ref={ref} className="bg-transparent" />
    )),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = axios.create({
    baseURL: `https://reqres.in/api`,
});



function validateEmail(email) {
    const re =
        /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
    return re.test(String(email).toLowerCase());
}

export default function KeyUser() {
    var columns = [
        { title: "id", field: "id", hidden: true },
        {
            title: "First name", field: "firstName",
            cellStyle: {
                minWidth: 200
            },
            validate: (rowData) =>
                rowData.firstName === undefined
                    ? { isValid: false, helperText: 'Mandatory' }
                    : rowData.firstName === ''
                        ? { isValid: false, helperText: 'Mandatory' }
                        : !validName.test(rowData.firstName) ? { isValid: false, helperText: 'No special/space characters allowed', }
                            : rowData.firstName.length > 50 ? { isValid: false, helperText: 'Should be less than 50 characters' } : true

        },
        {
            title: "Last name", field: "lastName",
            cellStyle: {
                minWidth: 200
            },
            validate: (rowData) =>
                rowData.lastName === undefined
                    ? { isValid: false, helperText: 'Mandatory' }
                    : rowData.lastName === ''
                        ? { isValid: false, helperText: 'Mandatory' }
                        : !validName.test(rowData.lastName) ? { isValid: false, helperText: 'No special/space characters allowed' }
                            : rowData.lastName.length > 50 ? { isValid: false, helperText: 'Should be less than 50 characters' } : true
        },
        {
            title: "Email", field: "emailId",
            validate: (rowData) =>
                rowData.emailId === undefined
                    ? { isValid: false, helperText: 'Mandatory' }
                    : rowData.emailId === ''
                        ? { isValid: false, helperText: 'Mandatory' }
                        : !validEmail.test(String(rowData.emailId).toLowerCase()) ? "Invalid Email Format"
                            : rowData.emailId.length > 200 ? { isValid: false, helperText: 'Should be less than 200 characters' }
                                : ((!rowData.id && isEmailExists(rowData.emailId)) || (rowData.id && isEmailExists(rowData.emailId, rowData.id))) ? { isValid: false, helperText: 'Email Id already exists' } : true
        },
        {
            title: "Status",
            field: "status",
            lookup: { true: "Active", false: "Inactive" },
            validate: (rowData) =>
                rowData.status === undefined
                    ? { isValid: false, helperText: 'Mandatory' }
                    : rowData.status === ''
                        ? { isValid: false, helperText: 'Mandatory' }
                        : true
        },
    ];
    const [data, setData] = useState([]);
    const token = useSelector((state) => state.saveTokenReducer.token);
    const bearerToken = useSelector(
      (state) => state.saveBearerTokenReducer.bearerToken
    );
    const uId = useSelector((state) => state.saveUIDReducer.uid);
    const userData = useSelector(
      (state) => state.storeUserDetailsReducer.userData
    );
    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const loginUserDetails = useSelector(
      (state) => state.storeUserDetailsReducer.userData
    );
    const [isLoader, setIsLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleClose = (e) => {
      setOpen(false);
    };
    const handleSnackOpen = (text, type) => {
      setSnackMessage(text);
      setAlertType(type);
      setOpen(true);
    };
    const validName = new RegExp("^[-a-zA-Z0-9-()]+(s+[-a-zA-Z0-9-()]+)*$");
    const validEmail = new RegExp(
      /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/
    );

    function getUserList() {
      //const token = localStorage.getItem('access_token');
      fetch(baseApiURL + "/User/UsersByDistributor", {
        headers: {
          Authorization: "Bearer " + bearerToken,
          UserEmail: userData.emailId,
          userId: uId,
          RoleName: userData.roleName,
          Gid: userData.gid,
          Roleid: userData.roleId,
          SiemensToken: token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setUserDetails(data.result);
        })
        .catch((error) => console.log("Error:", error));
    }

    function isEmailExists(emailId) {
      const obj = userDetails.find((c) => c.emailId == emailId);
      return obj ? true : false;
    }

    function isEmailExists(emailId, id) {
      const obj = userDetails.find((c) => c.emailId == emailId);

      return obj && obj.id !== id ? true : false;
    }

    const handleRowUpdate = (newData, oldData, resolve) => {
      //validation
      let errorList = [];
      newData.modifiedBy = userDetails.Id;
      if (newData.firstName === "") {
        errorList.push("Please enter first name");
      }
      if (newData.lastName === "") {
        errorList.push("Please enter last name");
      }
      if (newData.emailId === "" || validateEmail(newData.emailId) === false) {
        errorList.push("Please enter a valid email");
      }
      if (errorList.length === 0) {
        const options = {
          method: "put",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + bearerToken,
            UserEmail: userData.emailId,
            userId: uId,
            RoleName: userData.roleName,
            Gid: userData.gid,
            Roleid: userData.roleId,
            SiemensToken: token,
          },
          body: JSON.stringify(newData),
        };
        return fetch(
          baseApiURL +
            "/User/DistributorUserEdit?newData=" +
            newData,
          options
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.result.response) {
              handleSnackOpen("User Updated Successfully", "success");
              resolve();
              setIsLoader(false);
              getUserList();
            } else {
              handleSnackOpen("Error in updating User", "error");
              //reject();
              setIsLoader(false);
            }
          })
          .catch((error) => {
            // handleSnackOpen("Exception : " + error, "error");
            //reject();
            setIsLoader(false);
          });
      } else {
        setErrorMessages(errorList);
        setIserror(true);
        resolve();
      }
    };

    const handleRowAdd = (newData, resolve, id) => {
      //validation
      let errorList = [];
      if (newData.firstName === undefined) {
        errorList.push("Please enter first name");
      }
      if (newData.lastName === undefined) {
        errorList.push("Please enter last name");
      }
      if (
        newData.emailId === undefined ||
        validateEmail(newData.emailId) === false
      ) {
        errorList.push("Please enter a valid email");
      }
      if (errorList.length < 1) {
        const options = {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + bearerToken,
            UserEmail: userData.emailId,
            userId: uId,
            RoleName: userData.roleName,
            Gid: userData.gid,
            Roleid: userData.roleId,
            SiemensToken: token,
          },
          body: JSON.stringify(newData),
        };
        return fetch(
          baseApiURL +
            "/User/DistributorAddUserDetails?newData=" +
            newData ,
          options
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.result.response) {
              handleSnackOpen("User Added Successfully", "success");
              resolve();
              setIsLoader(false);
              getUserList();
            } else {
              handleSnackOpen("Error in adding User", "error");
              //reject();
              setIsLoader(false);
            }
          })
          .catch((error) => {
            // handleSnackOpen("Exception : " + error, "error");
            //reject();
            setIsLoader(false);
          });
      } else {
        setErrorMessages(errorList);
        setIserror(true);
        resolve();
      }
    };

    const handleRowDelete = (oldData, resolve) => {
      const options = {
        method: "put",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearerToken,
          UserEmail: userData.emailId,
          userId: uId,
          RoleName: userData.roleName,
          Gid: userData.gid,
          Roleid: userData.roleId,
          SiemensToken: token,
        },
        body: JSON.stringify(oldData),
      };
      return fetch(
        baseApiURL +
          "/User/UserDelete?oldData=" +
          oldData,
        options
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.result.response) {
            handleSnackOpen("User InActivated Successfully", "success");
            resolve();
            setIsLoader(false);
            getUserList();
          } else {
            handleSnackOpen("Error in inactivating User", "error");
            //reject();
            setIsLoader(false);
          }
        })
        .catch((error) => {
          console.log("Error : ", error);
        });
    };

    useEffect(() => {
        if (token) {
            getUserList();
        }
    }, [token, userDetails.id]);

    return (
        <div className="bg-landing-page">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <h3>Partner User List</h3>
                </Grid>
                <Grid item xs={12}>
                    {/*   <div>
            {iserror && (
              <Alert severity="error">
                {errorMessages.map((msg, i) => {
                  return <div key={i}>{msg}</div>;
                })}
              </Alert>
            )}
          </div>*/}
                    <MaterialTable
                        title=""
                        columns={columns}
                        data={userDetails}
                        icons={tableIcons}
                        options={{
                            headerStyle: tableHeaderStyle,
                            draggable: false,
                        }}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve) => {
                                    handleRowUpdate(newData, oldData, resolve);
                                }),
                            onRowAdd: (newData) =>
                                new Promise((resolve) => {
                                    handleRowAdd(newData, resolve, loginUserDetails.id);
                                }),
                            onRowDelete: (oldData) =>
                                new Promise((resolve) => {
                                    handleRowDelete(oldData, resolve);
                                }),
                        }}
                    />
                </Grid>
                <SnackbarCustom
                    open={open}
                    message={snackMessage}
                    alertType={alertType}
                    handleClose={handleClose}
                />
            </Grid>
        </div>
    );
}
