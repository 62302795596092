
import { Grid } from "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import axios from "axios";
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import { tableHeaderStyle } from "../../SharedComponents/SharedComponentStyles";
import { useDispatch, useSelector } from "react-redux";
import { baseApiURL } from "../../Utilities/utility";

//import Alert from "@material-ui/lab/Alert";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => (
        <Clear {...props} ref={ref} className="bg-transparent" />
    )),
    Search: forwardRef((props, ref) => (
        <Search {...props} ref={ref} className="bg-transparent" />
    )),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const api = axios.create({
      //baseURL:  `http://localhost:48934/`
     baseURL: `https://leapuat.si-in.siemens.cloud/`
     //baseURL: `https://leap.si-in.siemens.cloud/`
});



export default function Download() {
    var columns = [
       // { title: "Course Id", field: "courseId" },
        { title: "Course Name", field: "courseName" },
        { title: "Category Name", field: "categoryName" },
        { title: "Status", field: "courseStatus" },
        { title: "Score", field: "courseScore", width:"2" },
        { title: "Time-Stamp", field: "lastDateStr" },

    ];
    const [data, setData] = useState([]); //table data

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
     const bearerToken = useSelector(
       (state) => state.saveBearerTokenReducer.bearerToken
     );
     const uId = useSelector((state) => state.saveUIDReducer.uid);
     const userData = useSelector(
       (state) => state.storeUserDetailsReducer.userData
     );

     function getHistoryList() {
       //const token = localStorage.getItem('access_token');
       fetch(
         baseApiURL +
           "/Course/GetUserCourseHistoryList" ,
         {
           headers: {
             Authorization: "Bearer " + bearerToken,
             UserEmail: userData.emailId,
             userId: uId,
             RoleName: userData.roleName,
             Gid: userData.gid,
             Roleid: userData.roleId,
             SiemensToken: token,
           },
         }
       )
         .then((response) => response.json())
         .then((data) => {
           setData(data.result);
         })
         .catch((error) => console.log("Error:", error));
     }
    useEffect(() => {
        if (token) {
            getHistoryList();
        }
    }, [token]);

    return (
        <div className="bg-landing-page">
            <Grid container spacing={1}>
                <Grid item xs={12}>

                    <Grid item xs={12}>
                        <h3> History</h3>
                    </Grid>
                    <MaterialTable
                        title=""
                        columns={columns}
                        data={data}
                        icons={tableIcons}
                        options={{
                            headerStyle: tableHeaderStyle,
                            draggable: false,
                        }}

                    />
                </Grid>
            </Grid>
        </div>
    );
}