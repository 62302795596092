import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "../Pages/LoginRegister/LoginPage";
import UnauthorizedAccess from "../Pages/UnauthorizedAccess";

export const LoginRoute = (
  <Switch>
        <Route path="/" component={Login} />
        <Route render={() => <UnauthorizedAccess />} />
  </Switch>
);
