import React from "react";


export default function UnauthorizedAccess() {
    return (
        <div className="master-layout-block">
            <h2 className="clr-red">WARNING!!! You are not authorized to access this page.</h2>
            <h4 className="clr-dark-blue">Contact Administrator if there is an issue with your request.</h4>
            

        </div>
    );
}

