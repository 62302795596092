export const authUserAction = (gid) => {
  return {
    type: "myIDLogin",
    payLoad: gid,
  };
};
export const isVerifiedOTPAction = (response) => {
  return {
    type: "isVerifiedOTP",
    payLoad: response,
  };
};
export const storeUserDetailsAction = (userData) => {
  return {
    type: "storeUserData",
    payLoad: userData,
  };
};
export const saveAccessTokenAction = (token) => {
  return {
    type: "saveAccessToken",
    payLoad: token,
  };
};
export const errorMessageAction = (errorMessage) => {
  return {
    type: "errorMessage",
    payLoad: errorMessage,
  };
};

//Bulk Upload Success
export const bulkUploadSuccessAction = (uploadResponse) => {
  return {
    type: "bulkUploadSuccess",
    payLoad: uploadResponse,
  };
};

export const saveExternalEmailAction = (email) => {
    return {
        type: "externalEmail",
        payLoad: email,
    };
};
export const saveBearerTokenAction = (token) => {
    return {
        type: "saveBearerToken",
        payLoad: token,
    };
};
export const saveUIDAction = (token) => {
    return {
        type: "saveUID",
        payLoad: token,
    };
};
