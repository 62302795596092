import React, { useState, useEffect } from "react";
import {
    Container,
    Grid,
    Button,
} from "@material-ui/core";

import Rating from '@material-ui/lab/Rating';
import demoImage from "../../assets/images/others/BasicsOfControlProducts.jpg";
import "./CourseDetails.scss";
import { baseApiURL } from "../../Utilities/utility";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../SharedComponents/Elements/Loading/Loading";
import { useLocation } from "react-router-dom";
import SCORMAdapter from "../../SCORM/scormAdapter";
import * as FileSaver from "file-saver";

export default function CourseDetails() {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.saveTokenReducer.token);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const bearerToken = useSelector(
        (state) => state.saveBearerTokenReducer.bearerToken
    );
    const uId = useSelector((state) => state.saveUIDReducer.uid);
    const [courseDetails, setCourseDetails] = useState({});
    const [authorDetails, setAuthorDetails] = useState([]);
    const [rating, setRating] = useState();
    const location = useLocation();
    const courseInfoId = location.state.courseInfoId;
    const [value, setValue] = useState(0);
    const [avgRating, setAvgRating] = useState(0);
    const [count, setCount] = useState(0);

    window.API = new SCORMAdapter();

    function downloadCertificate() {
        window.location.href =
            baseApiURL +
            "/Course/DownloadCertificate?FilePath=" +
            courseDetails.certificateURL;
    }

    function getCourseDetails() {
        fetch(
            baseApiURL +
            "/Course/GetCourseDetails?courseId=" + courseInfoId,
            {
                headers: {
                    Authorization: "Bearer " + bearerToken,
                    UserEmail: userDetails.emailId,
                    userId: uId,
                    RoleName: userDetails.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    SiemensToken: token,
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setCourseDetails(data.result);
                setValue(data.result.rating);
            })
            .catch((error) => console.log("Error:", error));
    }

    function getCourseAvgRating() {
        fetch(baseApiURL + "/Course/GetCourseAvgRating?courseId=" + courseInfoId, {
            headers: {
                Authorization: "Bearer " + bearerToken,
                UserEmail: userDetails.emailId,
                userId: uId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                SiemensToken: token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setAvgRating(data.result.Average);
                setCount(data.result.Count);
            })
            .catch((error) => console.log("Error:", error));
    }

    function getAuthorName() {
        fetch(baseApiURL + "/Course/GetMstCourseAuthors?courseId=" + courseInfoId, {
            headers: {
                Authorization: "Bearer " + bearerToken,
                UserEmail: userDetails.emailId,
                userId: uId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                SiemensToken: token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setAuthorDetails(data.result);
            })
            .catch((error) => console.log("Error:", error));
    }

    function getRating(rating) {
        fetch(
            baseApiURL +
            "/Course/SaveRatingDetails?courseId=" +
            courseInfoId +
            "&rating=" +
            rating,
            {
                headers: {
                    Authorization: "Bearer " + bearerToken,
                    UserEmail: userDetails.emailId,
                    userId: uId,
                    RoleName: userDetails.roleName,
                    Gid: userDetails.gid,
                    Roleid: userDetails.roleId,
                    SiemensToken: token,
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setValue(rating);
                window.location.reload();
            })
            .catch((error) => console.log("Error:", error));
    }

    const openCourse = (e) => {
        var courseUrl =
            baseApiURL +
            "/assets/courses/" +
            courseDetails.courseFolderName +
            "/index_lms.html";
        //window.open(courseUrl, "newwindow", "height=800, width=1000, toolbar=no, menubar=no, scrollbars=no,location=no,resizable=yes, status=no");
        window.API.loadCourse(
            courseUrl,
            courseDetails.courseId,
            userDetails.id,
            courseDetails.suspendData
        );
    };

    useEffect(() => {
        if (token && userDetails.id) {
            getCourseDetails();
            getAuthorName();
            getCourseAvgRating();
        }
    }, [token, userDetails.id]);

    return (
        <>
            <div className="bg-landing-page">
                <Container maxWidth="xl">
                    <Grid container className="homepage-container">
                        <Grid container item xs={12} className="bg-light">
                            <Grid item xs={12} md={4} className="p-10px">
                                <img
                                    src={courseDetails.courseImage}
                                    alt="current course"
                                    className="resume-watch-image"
                                />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <div className="course-info">
                                    <h2 className="mb-0">{courseDetails.courseName}</h2>
                                    <Rating
                                        name="disabled"
                                        value={avgRating}
                                        disabled
                                        precision={0.1}
                                    />
                                    <p className="">
                                        {"(" + avgRating + " stars from " + count + " votes)"}
                                    </p>
                                    <p className="">{courseDetails.oneLineSummary}</p>
                                    <div className="d-flex jc-space-bt">
                                        {/*<a href="#">*/}
                                            <Button
                                                className="leap-button--primary rseume-course-btn mb-1 mt-1"
                                                id="ResumeCourseBtn"
                                                onClick={openCourse}>
                                                Start Course
                                            </Button>
                                        {/*</a>*/}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container item Xs={12} className="bd-bottom mt-1">
                            <h3 className="overview-title"> Overview </h3>
                        </Grid>

                        <Grid container item xs={12} className="mt-1 mb-2">
                            <Grid item xs={12} md={9} className="">
                                <Grid container item xs={12} className="details-block p-1">
                                    <h3>Details</h3>

                                    <p className="m-0">{courseDetails.courseDescription}</p>
                                </Grid>
                                <Grid container item xs={12} className="details-block p-1 mt-1">
                                    <h3>Summary</h3>

                                    <p className="m-0">{courseDetails.courseSummary}</p>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    className="details-block  p-1 mt-1">
                                    <h3>Objective</h3>

                                    <p className="m-0">{courseDetails.courseObjective}</p>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3} className="">
                                <div className="extras-block p-10px">
                                    <h3>At a glance</h3>
                                    <div className="border-dotted d-flex jc-space-bt">
                                        <span>Course Score</span>
                                        <span>{courseDetails.courseScore}</span>
                                    </div>
                                    <div className="border-dotted d-flex jc-space-bt mt-1">
                                        <span>Level </span>
                                        <span>{courseDetails.level}</span>
                                    </div>
                                    <div className="border-dotted d-flex jc-space-bt mt-1">
                                        <span>Duration </span>
                                        <span>{courseDetails.duration}</span>
                                    </div>
                                    <div className="border-dotted d-flex jc-space-bt mt-1">
                                        <span>Update </span>
                                        <span>{courseDetails.updateStr}</span>
                                    </div>
                                    <div className="border-dotted d-flex jc-space-bt mb-1">
                                        <span className="align-center">Download Certificate </span>
                                        <span>
                                            {courseDetails.certificateURL == "" ||
                                                courseDetails.certificateURL == null ||
                                                courseDetails.certificateURL == "undefined" ? (
                                                <span></span>
                                            ) : (
                                                <Button
                                                    className="clr-green"
                                                    onClick={downloadCertificate}>
                                                    Download
                                                </Button>
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="resume-block p-1 mt-1">
                                    <h3>Rate the Content</h3>
                                    <Rating
                                        name="simple-controlled"
                                        value={value}
                                        onChange={(event, newValue) => {
                                            getRating(newValue);
                                        }}
                                    />
                                </div>
                                <div className="resume-block  p-1 mt-1">
                                    <h3>Authors</h3>
                                    <h4>
                                        {authorDetails.map((item, index) => (
                                            <p> {item.authorName}</p>
                                        ))}
                                    </h4>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
}
