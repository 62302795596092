import { combineReducers } from "redux";

let userInitialState = {
  gid: "",
};
let tokenInitialState = {
  token: "",
};
let userDataInitialState = {
  userData: {
    gid: "",
    roleId: 0,
    roleName: "",
    userEmail: "",
    userFirstName: "",
    userLastName: "",
    userId: 0,
  },
};
let errorMessageInitialState = "";

const authUserReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case "myIDLogin": {
      return {
        gid: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
const saveTokenReducer = (state = tokenInitialState, action) => {
  switch (action.type) {
    case "saveAccessToken": {
      return {
        token: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

const storeUserDetailsReducer = (
  state = userDataInitialState,
  { type, payLoad }
) => {
  switch (type) {
    case "storeUserData": {
      return {
        userData: {
          ...payLoad,
        },
      };
    }
    default: {
      return state;
    }
  }
};

const errorMessageReducer = (
  state = errorMessageInitialState,
  { type, payLoad }
) => {
  switch (type) {
    case "errorMessage": {
      return payLoad;
    }
    default: {
      return state;
    }
  }
};

let uploadResponseInitialState = {
  response: false,
  responseMsg: "",
};
const bulkUploadSuccessReducer = (
  state = uploadResponseInitialState,
  action
) => {
  switch (action.type) {
    case "bulkUploadSuccess": {
      return { ...action.payLoad };
    }
    default: {
      return state;
    }
  }
};

let isVerifiedOTPInitialState = false;
const isVerifiedOTPReducer = (state = isVerifiedOTPInitialState, action) => {
  switch (action.type) {
    case "isVerifiedOTP": {
      return action.payLoad;
    }
    default: {
      return state;
    }
  }
};

let externalEmail = {
    email: "",
};

const saveExternalEmailReducer = (state = externalEmail, action) => {
    switch (action.type) {
        case "externalEmail": {
            return {
                email: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
let bearerToken = {
    token: "",
};
const saveBearerTokenReducer = (state = bearerToken, action) => {
    switch (action.type) {
        case "saveBearerToken": {
            return {
                bearerToken: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
let uid = {
    token: "",
};
const saveUIDReducer = (state = uid, action) => {
    switch (action.type) {
        case "saveUID": {
            return {
                uid: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};

export const masterReducer = combineReducers({
  authUserReducer: authUserReducer,
  saveTokenReducer: saveTokenReducer,
  storeUserDetailsReducer: storeUserDetailsReducer,
  errorMessageReducer: errorMessageReducer,
  bulkUploadSuccessReducer: bulkUploadSuccessReducer,
   isVerifiedOTPReducer: isVerifiedOTPReducer,
    saveExternalEmailReducer: saveExternalEmailReducer,
    saveBearerTokenReducer: saveBearerTokenReducer,
    saveUIDReducer: saveUIDReducer,
});
