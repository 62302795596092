import {
    authUserAction,
    storeUserDetailsAction,
    saveAccessTokenAction,
    errorMessageAction,
    saveExternalEmailAction, saveUIDAction, saveBearerTokenAction
} from "../actions";
import { baseApiURL } from "../../Utilities/utility";
import { useSelector } from "react-redux";
let gID,
    generatedToken = "";

export function authenticateUserBasedOnValidToken(dispatch, newToken) {

    var formdataPart1 = new FormData();
    formdataPart1.append("Token", newToken);


    const options = {
        method: "post",
        processData: false,
        mimeType: "multipart/form-data",
        contentType: false,
        body: formdataPart1,
    };


    return fetch(baseApiURL + "/Login/Getuser", options)
        .then((response) => response.json())
        .then((data) => {
            if (data.result) {
                dispatch(storeUserDetailsAction(data.result.response));
                dispatch(saveUIDAction(data.result.uid));
                dispatch(saveBearerTokenAction(data.result.tokenString));
                return true;
            } else {
                return false;
            }
        })
        .catch((error) => {
            return false;
        });
}

// We are getting ONLY token from this api
export function validateTheUserBasedOnApi(dispatch, email) {

    const systemUser = {
        userEmailId: email,
    };
    const options = {
        method: "post",
        mode: "cors",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Origin": `${window.location.origin}`,
        },
        body: JSON.stringify(systemUser),
    };

    return fetch(baseApiURL + "/Login/ValidateRequest", options)
        .then((res) => res.json())
        .then((res_token) => {
            
            generatedToken = res_token.token;
            dispatch(saveAccessTokenAction(generatedToken));

            return generatedToken;
        })
        .catch((error) =>
            dispatch(errorMessageAction("Invalid Email or Password"))
        );
}

export function authenticateExternalUser(newToken, userData) {
    const options = {
        method: "post",
        mode: "cors",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: "Bearer " + newToken,
            "Access-Control-Allow-Origin": `${window.location.origin}`,
        },
        body: JSON.stringify(userData),
    };

    return fetch(baseApiURL + "/Login/AuthenticateExternalUser", options)
        .then((response) => response.json())
        .then((data) => {
            if (data.result) {
                return data.result;
            } else {
                return false;
            }
        })
        .catch((error) => {
            return false;
        });
}
